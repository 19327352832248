module.exports = {
  redirectBaseUrl: '',
  cognito: {
    userPoolId: 'eu-west-2_szYZyA2Ft',
    userPoolWebClientId: '7og87ngmillvfrqq78h16q08n1',
    platformSubdomain: 'stage',
    cognitoSubdomain: 'platform-stage-kaluza',
    userManagementURL: 'https://user-management.stage.gridsvc.net/api',
    loginRedirectDestination: 'https://platform.stage.kaluza.com/callback',
    logoutRedirectDestination: 'https://platform.stage.kaluza.com/'
  },
  cookies: {
    domain: 'platform.stage.kaluza.com'
  }
};
